<template>
    <div class="container-dota" v-if="userInfo">
      <li><router-link to="/lastkeys">Склад ключей</router-link></li>
      <li><router-link to="/getlogs">Логи</router-link></li>
       <div class="admin-logs" v-if="InfKeys > 500">
        <li><router-link to="/admingetlogs">История Игроков</router-link></li>
        <li><router-link to="/getusers">История Ключей</router-link></li>
       </div>
        <span>
          <img 
          width="290"
          class="img-fluid rounded" src="../assets/gifs/noLogged.gif">
          <div class="pt-2">  
          </div>
        </span>
        <div class="zarabotay mb-2">
          <h3>Партнерская программа - 10%</h3>
          <span>Заработай с нами! <br>Если у вас есть друг или знакомый приглашайте в наши ряди и получите Кэшбек от его покупок!<br></span>
          <span>10% - Дадим с его покупок На Yoomoney или на Вашу карту<br>Интересно? Пишите в тех поддержку и мы вам по подробнее обясним!</span>
      </div>
        <div v-if="userInfo" class="pt-2">
         <div class="profil-info">
          <div class="profil-helper">
            <h3>Ваш ID: {{ userInfo.id }}</h3> 
          <img src="../assets/img/keysAmount.png"
            width="50"
            class="rounded">
            <span 
              class="amount-txt"
              >{{ InfKeys }}
            </span>
          </div>
          <br>
          <p>Ваш Никнейм: {{ userInfo.username }}</p>
          <p>Ваша почта: {{ userInfo.email }}</p>
         </div>
          <h3 @click="logout"><br>
              <router-link v-if="userInfo" to="/"><li>Выйти</li></router-link>  
          </h3>
        <span>Аккаунт был создан: {{ userInfo.createdAt }}</span>
       </div>
    </div>
    <div v-else>
      <notFound/>
    </div>
</template>
  
<script>
import notFound from "@/pages/NotFound.vue"
import {ref, onBeforeMount} from 'vue'
import axios from 'axios'
import configs from '../configs/config'
import { useRouter } from 'vue-router'

  export default {
    components:{notFound},
    setup() {
      const user = window.localStorage.getItem('yf_395493')
      const userInfo = JSON.parse(user)
      const InfKeys = ref('')
      const isPlayer = ref('')

      const userJwt = window.localStorage.getItem('sc_359649')
      const userJwtG = userJwt

      const router = useRouter()

      onBeforeMount(() => {
            getAmount();
            getIsPlayer();
      })

      const getAmount = async () => {
            await axios.get(`${configs.apiURL}/api/users/${userInfo.id}?fields=Amount`, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                }
             })
                .then(response => {
                    InfKeys.value = response.data.Amount
                    
                })
                .catch(error => {
                    if(error.response.status === 401){
                      localStorage.clear()
                      router.push({ path: '/'})
                      setTimeout(() => {
                        window.location.reload()
                      }, 2000);
                    }
                });
        }

        const getIsPlayer = async () => {
            await axios.get(`${configs.apiURL}/api/users/${userInfo.id}?fields=isPlayer`, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                }
             })
                .then(response => {
                    isPlayer.value = response.data.isPlayer
                    
                })
                .catch(()=>{
                  alert('Нажмите на свои Профиль чтобы узнать причину')
                })
        }
       
      return {
        user,
        userInfo,
        InfKeys,
        getAmount,
        getIsPlayer,
        isPlayer
      }
    },
    methods: {
      logout(){
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.location.reload()

      }
    }
}
</script>
  
<style scoped>
h3{
  font-size: 16px;
  font-family: 'vue-w25';
}
iframe{
  max-width: 750px;
  margin: auto;
}
.background-acc{
  background: #f7f9fa;
}

li{
    font-size: x-large;
    color: black;
    margin-bottom: 10px;
  }
p{
    font-family: 'vue-w25';
    font-weight: 300;
    font-size: 15px;
    color: #333;
}
.btn-vuedota {
    color: rgb(183, 43, 26);
    border-radius: 15%;
}
.btn-vueAccess {
    width: 150px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(0, 255, 89);
    background: rgb(0, 255, 89);
    border: 2px solid rgb(0, 255, 89);
}
@media only screen and (max-width: 420px) {
  p{
    font-family: 'vue-w22';
    font-weight: 300;
    font-size: 13px;
    color: #333;
  }


}

.zarabotay span{
  font-size: 13px;
  font-family: monospace;
  font-weight: 700;
}
  
</style>
  