<template>
  <div class="container-xxl" v-if="userInfo">
    <div class="container-dota log-suport">
        <h3>Тех поддержка</h3>
        <p>Информации внизу</p>
        <p><a href="https://vk.com/g2dev" target="_blank">VK</a></p>
        <p><a href="https://t.me/g2dev" target="_blank">Telegram</a></p>
        <p><a href="https://www.facebook.com/profile.php?id=100087746501429" target="_blank">Facebook</a></p>
    </div>

    <div class="form-feedback">
        <form @submit.prevent="FeedBack">
            <h3
            class="mt-2">Ваш ID: {{userInfo.id}}</h3>
            <h3>Имя:{{userInfo.username}}</h3>
                <div class="my-5">
                    <h3>Суть вашей проблемы:</h3>
                    <input type="text" v-model="aboutProblems">
                </div>

                <div class="my-5">
                    <h3>Ваш Telegram либо какая то соц.сет:</h3>
                    <input type="text" v-model="contact">
                </div>
                        
            <button :disabled="aboutProblems.length < 10" class="btn btn-primary" type="submit">
                Отправить
            </button>
        </form>
    </div>
  </div>
  <div 
  v-else
  class="container-dota">
    <h3 
        class="mt-5" style="font-family: 'vue-w25';">Для доступа к данной<br> странице нужно войти на сайт</h3>
  </div>
</template>

<script>
import {ref} from 'vue' 
import axios from 'axios'
import configs from '../configs/config'

export default {
  setup() {

    const user = window.localStorage.getItem('yf_395493')
    const userInfo = JSON.parse(user)

    const contact = ref('')
    const aboutProblems = ref('')

    const FeedBack = async () => { 
       await axios.post(`${configs.BackURL}/sendMeMessage`,
        {
            text: '<pre>Заявка с сайта!\n</pre>' + '<b>ID:</b> '+ userInfo.id + '\n<b>Имя:</b> ' + userInfo.username + '\n<b>Проблема: </b> ' + aboutProblems.value + '\n<b>Контакты: </b> ' + contact.value
        })
        .then(() => {
            alert('Сообщение Успешно отправлено!')
            window.location.reload()
        })
       .catch(err => {
            console.log(err)
       })
    } 


    return {
        FeedBack,
        aboutProblems,
        user,
        userInfo,
        contact
    }
  }
}
</script>

<style>
.log-suport{
    margin: 55px auto;
    background: #fff;
    padding: 15px;
    border-radius: 15px;
}
@media(max-width: 450px){
    .log-suport{
        background: #fff;
        padding: 5px;
        border-radius: 15px;
        margin-bottom: 10px;
    }
}
</style>