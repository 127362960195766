<template>
    <h4 
    style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
        v-if="loading">
            Загрузка...
    </h4>
 <div 
 class="F5" v-else> 
        <div 
            class="F4"
            :key="Herois"
            v-for="Herois in Dota">
            <h4>{{ Herois.HWID }}</h4><br>
            <h5 v-if="Herois.USERNAME">Логин: {{ Herois.USERNAME }}</h5>
            <p>{{ Herois.KEY }}</p>
            <h4>{{ Herois.ShowDate }}</h4>
            <span>{{ Herois.Time }}</span>
            <h5>ID: {{ Herois.IDFSITE }}</h5>
    </div>
 </div>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue'
import axios from 'axios'
import configs from '../configs/config.js'

    const Dota = ref('')
    const loading = ref(true)

    async function getHwid() {
        try {
        loading.value = true;
        const response = await axios.get(`${configs.BackJSON}/Dota/`);
        Dota.value = response.data
        } catch (err) {
        console.log(err)
        } finally {
            setTimeout(() => {
                loading.value = false;
            }, 1000);
        }
    }

    onBeforeMount(() => {
        getHwid()
    })

</script>
<style scoped>
.F5{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    width: 100%;
    margin-top: 25px
}
.F4{
    width: 24.5%;
    border-radius: 5px;
    border: 1px solid red;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: overlay;
    padding: 3px;
}
.F4 p{
    font-size: 3px;
}
.F4 h4{
    font-size: 12px;
    font-weight: 700;
    color: black;
}
.F4 h5{
    font-size: medium;
    text-align: center;
    font-weight: 800;
}
@media(max-width: 480px){
    .F4{
        width: 48%;
    }
}
</style>