<template>
    <h4 
    style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
    v-if="loading">
        Загрузка...
</h4>
 <div 
 v-else
 class="Logs-control">
        <div class="logs" :key="log.id" v-for="log in logs">
        <div class="f1">ID: {{ log.IDFSITE }}</div>
        <div class="f1">Ваша почта: {{ log.Email }}</div>
        <div class="f1">Время: {{ log.Time }}</div>
        <div class="f1" v-if="log.IP">IP: {{ log.IP }}</div>
        <div class="f1" v-else>IP: Nothing</div>
   </div>
 </div>
</template>
<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { onMounted, ref } from 'vue'

const user = window.localStorage.getItem('yf_395493')
const userInfo = JSON.parse(user)
const loading = ref(true)

const logs = ref('')

 onMounted(() => {
    getInfo()
 })

async function getInfo() {
    try {
    loading.value = true;
    const response = await axios.get(`${configs.BackJSON}/HackPlayersInfo?IDFSITE=${userInfo.id}`)
    logs.value = response.data
    } catch (err) {
    console.log(err)
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 1000);
    }
}
</script>
<style scoped>
.Logs-control{
    margin-top: 25px;
    background-color: #caddf021;
    padding: 70px;
    display: flex;
    flex-wrap: wrap-reverse;
}
.logs{
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-evenly;
}
.f1{
    font-family: 'vue-coder';
    font-size: x-large;
    border: 1px solid #c9daeb;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
}
@media(max-width: 480px){
    .Logs-control{
        padding: 0;
    }
    .f1{
        margin-right: 0;
        font-size: small;
        padding: 1px;
    }
}
</style>