<template>
    <h4 
    style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
    v-if="loading">
        Загрузка...
</h4>
 <div 
 v-else
 class="Logs-control">
    <div class="logs" 
    :key="log.id" v-for="log in logs">
        <div class="f1">ID: {{ log.IDFSITE }}</div> <br>
        <div class="f1" v-if="log.USERNAME">{{ log.USERNAME }}</div>
        <div class="f1">Ваша почта: {{ log.Email }}</div>
        <div class="f1">Время: {{ log.Time }}</div>
        <div class="f1">IP: {{ log.IP }}</div>
   </div>
 </div>
</template>
<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { onMounted, ref } from 'vue'

const loading = ref(true)

const logs = ref('')

 onMounted(() => {
    getInfo()
 })

async function getInfo() {
    try {
    loading.value = true;
    const response = await axios.get(`${configs.BackJSON}/HackPlayersInfo/`)
    logs.value = response.data
    } catch (err) {
    console.log(err)
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 1000);
    }
}
</script>
<style scoped>
.Logs-control{
    margin-top: 25px;
    background-color: #caddf021;
    display: flex;
    flex-flow: wrap-reverse;
    justify-content: center;
    width: 100%;
}
.logs{
    margin-top: 25px;
    background-color: #caddf021;
    width: 15.5%;
    overflow: hidden;
}
.f1{
    font-family: 'vue-coder';
    font-size: small;
    border: 1px solid #c9daeb;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
}
@media(max-width: 480px){
    .Logs-control{
        padding: 0;
    }
    .f1{
        margin-right: 0;
        font-size: small;
        padding: 1px;
    }
    .logs{
        width: 49.5%;
    }
}
</style>