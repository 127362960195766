<template>
 <div class="container-xxl" v-if="userKeys">
    <div class="container-dota" v-if="userKeys.length > 0">
    <h3>Это ваш Ключ От Мастера Йода</h3>
    <img src="../assets/img/Lichkey.jpg" 
    class="img-fluid">
    <div class="container" v-if="userBaseKey == null">
            <h3>Упс! Ничего нет!</h3>
     </div> 
     <div v-else>  
        <h3>Нажмите кнопку <span>Скопировать</span> чтобы заполучить ключ</h3>
        <textarea 
            rows="9" 
            cols="52"
            v-model="userBaseKey"/><br>
        <button 
            @click="CopyHwid"
            class="btn btn-primary mb-4">Скопировать</button>
            <h4>{{ userHWIDG }}</h4>
            <p v-if="userShowTimes.length > 5">Работает до: {{ userShowTimes }}</p>
            <p v-else>Примечание:  Год, Месяц , Дни</p>
      </div>
    </div>
    <div v-else><notFound/></div>
 </div>
 <div v-else><notFound/></div>
</template>

<script setup>
import notFound from '../pages/NotFound.vue'
import { ref, onBeforeMount } from 'vue'
import axios from 'axios'
import configs from '../configs/config.js'

    const userKeys = window.localStorage.getItem('identification')
    const userKeysA = userKeys
    const userJwt = window.localStorage.getItem('sc_359649')
    const userJwtG = userJwt
    const userBaseKey = window.sessionStorage.getItem('KeyFromBase')
    const userBaseKeys = ref(userBaseKey)
    const userBaseTime = window.sessionStorage.getItem('DateFromBase')
    const userBaseTimes = ref(userBaseTime)
    const userShowTime = window.sessionStorage.getItem('timeShow')
    const userShowTimes = ref(userShowTime)
    const userHWID = window.sessionStorage.getItem('HWID__')
    const userHWIDG = ref(userHWID)

    const CopyHwid = () => {
             try {
                 navigator.clipboard.writeText(userBaseKey)
                 alert('Вы успешно скопировали ключ!')
             } catch(e) {
                 console.log(e)
             }
         }

    onBeforeMount(() => {
        if(userBaseKey !== null) {
            update_keys()
        }
    })

    const update_keys = async () => {
                await axios({
                    method: 'PUT',
                    url: `${configs.apiURL}/api/users/${userKeysA}/?fields=HWID_KEYS&HWID_TIME`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userJwtG}`
                    },
                    data: {
                        'id': userKeysA,
                        'HWID_KEYS': userBaseKeys.value,
                        'HWID_TIME': userBaseTimes.value
                    }
                })
                .then(() => {
                })
                .catch(() => {
                    alert('Что-то не так!')
        })
    }
    

</script>

<style scoped>
textarea{
    width: 25%;
    height: auto;
    font-size: 6px;
    text-align: center;
    font-family: 'vue-w25';
    border-radius: 5px;
}
span {
    font-family: 'vue-w25';
    color: red;
    font-size: 22px;
}
.key-span {
    font-family: 'vue-w25';
    text-align: center;
    color: #222;
    font-size: 5px;
}
p{
    font-family: 'vue-w25';
    font-size: 15px;
    color: black;
}
img{
    max-width: 300px;
    min-width: 250px;
    height: 320px;
    border-radius: 15px;
    margin-bottom: 15px;
}
h3{
    font-family: 'vue-w25';
}
h4{
    font-family: 'vue-bold';
    color: black;
}
@media(max-width: 480px){
    textarea{
        font-size: 6.5px;
        width: 90%;
        height: 15vh;
    }
}
</style>