<template>
      <div class="box-card">
        <!-- <div class="boxleftContainer"> -->
            <video preload="auto" loop autoplay muted>
              <source type="video/webm" src="../assets/videos/dotaback.mp4" class="rounded">
          </video>
          <router-link to="/prices">
            <div class="buyknopka2">
              <span class="icon_maphack2"></span>
              <span class="buyknopka_title2">Купить</span>
              <span class="buyknopka_portred2"></span>
            </div>
          </router-link>
        </div>
           <div class="boxes-news">
            <h3 class="pb-3">Последные Новости</h3>
            <div class="row">
              <div class="col-md">
              <img 
                height="315"
                class="img-fluid rounded"
                src="@/assets/img/guides/kaelkey.jpg">
              </div>
              <div class="col-md">
                <router-link to="/kaelkey"><h5>Kaelkey - Программа</h5></router-link>
                <p>Программа для героя Инвокер! iCCupom разрешено использовать!</p>
              </div>
            </div>
          </div>

          <div class="boxes-news mt-3">
            <div class="row">
              <div class="col-md pt-3">
                <img 
                height="315"
                class="img-fluid rounded"
                src="@/assets/img/guides/Geomanser.jpg"
              >
              </div>
              <div class="col-md pt-3">
                <router-link to="/meepokey"><h5>Мипокей - Программа</h5></router-link>
                <p>Программа для героя Meepo! iCCupom разрешено использовать!</p>
              </div>
            </div>
          </div>

          <div class="boxes-news mt-3">
            <div class="row">
              <div class="col-md pt-3">
                <img 
                height="315"
                class="img-fluid rounded"
                src="@/assets/img/guides/dotatheme.jpg"
              >
              </div>
              <div class="col-md pt-3">
                <router-link to="/themeManager"><h5>Dota Theme manager</h5></router-link>
                <p>Программа для изменение внешний среди в Дотке</p>
              </div>
            </div>
          </div>
</template>

<script>
export default {

}
</script>

<style>
.boxleftContainer img{
  width: 100%;
  height: 295px;
  border-radius: 15px;
}
p{
  font-family: 'vue-w25';
  font-size: larger;
}
.boxes-news {
  width: 100%;
  border-radius: 15%;
}
video{
  height: 100%;
  object-fit: cover;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.box-card {
  height: auto;
}
.p-90{
  padding-top: 90px;
}
h5{
  font-size: xx-large;
  font-family: 'vue-w25';
  color: black;
}

.buyknopka2{
    display: flex;
    align-items: center;
    height: 62px;
    width: 309px;
    font-size: 24px;
    color: #fff;
    box-shadow: 3px 3px 13px rgb(0 0 0 / 13%);
    border-radius: 5px;
    background-image: url('../assets/img/btn-start-bg2.png');
    background-repeat: no-repeat;
    margin: 10px auto;
}
.icon_maphack2{
    width: 33.3%;
    height: 50px;
    margin: auto;
    background-image: url('../assets/img/ico-start-btn2.png');
    background-repeat: no-repeat;
}
.buyknopka_portred2{
    background-image: url('../assets/img/btn-start-ico2.png');
    background-repeat: no-repeat;
    display: block;
    height: 62px;
    width: 33.3%;
}

@media only screen and (max-width: 420px) {
  .box-card{
    height: auto;
    margin-bottom: 5px;
  }
  .p-90{
    padding-top: 90px;
  }
  h3{
    font-family: 'vue-w22';
    font-size: 15px;
  }
  p{
    font-size: small;
  }
  .boxleftContainer img{
    height: auto;
  }

}
</style>