<template>
  <div class="container-dota">
    <h3 class="txt-war">
        Прежде чем использовать нашу систему посмотрите этот видео.
    </h3>
    <div class="container mt-5">
        <h3>Инструкция</h3>
        <iframe src="https://www.youtube.com/embed/dBaMxdw5SKI?si=Y4kMSBcYyBg14NkD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <router-link to="/tester"><h3>Купить код активации тут</h3></router-link>
  </div>
</template>

<script>
</script>

<style scoped>
</style>