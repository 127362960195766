<template>
    <div class="container-dota">
      <div class="container-xxl"> 
        <div class="row">
          <div class="col-md mb-2">
            <div class="card-main log-form2">
             <img src="../assets/img/gifts_newyear.png">
            <h5>10 Дней в подарок!</h5>
            <h2>Фулл Мапхак</h2>  
            <h3>17.5$ / 1599руб</h3>  
            <h4 class="border-deckore">Бесплатное обновление: 94 / дней</h4>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2">
                                <span class="icon_maphack2"></span>
                                <span class="buyknopka_title2">Купить</span>
                                <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>    
          </div>
        <div class="col-md mb-2">
           <div class="card-main log-form2">
            <img src="../assets/img/gifts_newyear.png">
            <h5>1 Месяц в Подарок!</h5>
            <h2>Фулл Мапхак</h2>  
            <h3>28$ / 2599руб</h3>  
            <h4 class="border-deckore">Бесплатное обновление: 162 / дней</h4>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2 mb-5">
                            <span class="icon_maphack2"></span>
                            <span class="buyknopka_title2">Купить</span>
                            <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>     
            </div>
        <div class="col-md mb-2">
           <div class="card-main log-form2">
            <img src="../assets/img/gifts_newyear.png">
            <h5>3 Месяца в Подарок!</h5>
            <h2>Фулл Мапхак</h2>  
            <h3>49$ / 4799руб</h3> 
            <h4 class="border-deckore">Бесплатное обновление: 363 / дней</h4>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2 mb-5">
                            <span class="icon_maphack2"></span>
                            <span class="buyknopka_title2">Купить</span>
                            <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>     
            </div>
        </div>
        <div class="row mt-3">
          <div class="col-md mb-2">
            <div class="card-main log-form2">
            <h5>Наценка / 25%</h5>
            <h2>Фулл Мапхак</h2>  
            <h3>699р / 14дней</h3>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2">
                                <span class="icon_maphack2"></span>
                                <span class="buyknopka_title2">Купить</span>
                                <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>    
          </div>
        <div class="col-md mb-2">
           <div class="card-main log-form2">
            <h5>Наценка / 50%</h5>
            <h2>Фулл Мапхак</h2>  
            <h3>499р/ 7 Дней</h3>  
            <div class="card-dota">
                <div class="head-card">
                <div class="img-card">
                    <img :src="logo.imglogo" class="invoker-orbs">
                </div>
                </div>
                    <div class="info-card">
                        <p>Доступ ко всему функционалу</p>
                        <p>Быстрая помощь в поддержке</p>
                        <p>Доступ к Быстрым обновлением!</p>
                        <p class="mb-5">Доступ к Панели управлении</p>
                        <p><router-link to="/abouthack">Демонстрация хака</router-link></p>
                        <a target="_blank" href="https://t.me/g2dev">
                            <div class="buyknopka2 mb-5">
                            <span class="icon_maphack2"></span>
                            <span class="buyknopka_title2">Купить</span>
                            <span class="buyknopka_portred2"></span>
                            </div>
                        </a>
                    </div>
            </div>
           </div>     
            </div>
        </div>
      </div>
    </div>
    <router-view />
</template>
<script>
export default{
    data() {
        return {
            logo: {
                imglogo: require("../assets/img/invoker_orbs.png")
            }
        };
    }
}
</script>

<style scoped>
.log-form2{
    width: 99%;
    background: #fff;
    padding: 2px 0 5px 0;
    border-radius: 4%;
}
.log-form2 p{
    font-size: 15px;
    font-family: 'vue-w22';
    color: black;
}
.border-deckore{
    border-top: 1px double red;
    border-bottom: 5px double red;
}
.log-form2 h3{
    font-size: large;
    font-family: 'vue-w25';
    color: black;
    padding: 5px;
}
.log-form2 h4{
    font-family: 'vue-w25';
    color: black;
    font-size: small;
    padding: 5px;
}
.log-form2 h5{
    color: #fd0d0d99;
    background-color: #eaf5ff;
}

.log-form2 label{
  font-size: 15px;
}
.log-form2 img{
    width: 62px;
    height: auto;
}
.card-dota {
    width: 100%;
    height: 400px;
    text-align: center;
}
.col-md h2 {
    font-family: 'vue-w25';
    font-size: xx-large;
    color: black;
}

.card-dota h3 {
    font-family: 'vue-w22';
    font-size: 21px;
    color: black;
}
.card-dota h4 {
    font-family: 'vue-w22';
    font-size: 14px;
    color: whitesmoke;
}
.info-card p {
    font-size: medium;
    font-family: 'vue-w25';
    color: black;
} 
.head-card {
    width: 100%;
    height: 30%;
}
.info-card {
    width: 100%;
    height: 30%;
}
.card-dota img {
    width: 250px;  
}
.card-main:hover {
    border: 2px solid red;
    box-shadow: 1px 10px 40px -9px rgba(255,0,14,0.75);
}
</style>