<template> 

 <div class="container-xxl mit">
    <h3>Добро пожаловать в Мир Ключей!</h3>
    <p>Ваши монеты: <b class="amount-txt">{{ InfKeys }}</b></p>
    <div 
    class="container-xxl mt-5">
    <p style="color: red;">Пока не отправили запрос, монеты с вашего счета не снимаются!</p>
    <div class="container-costs mt-2 category-select">
        <div class="costs">
            <h4>1<img src="../assets/img/keysAmount.png"></h4>
            <h4>2 дней</h4>
            <h5 v-if="btn1 === true">Выбрано</h5>
        </div>
        <div class="costs">
            <h4>2<img src="../assets/img/keysAmount.png"></h4>
            <h4>7 дней</h4>
            <h5 v-if="btn2 === true">Выбрано</h5>
        </div>
        <div class="costs">
            <h4>5<img src="../assets/img/keysAmount.png"></h4>
            <h4>30 дней</h4>
            <h5 v-if="btn3 === true">Выбрано</h5>
        </div>
    </div>
    
   <div class="category-select mt-5">
    <div>
         <h3>Сколько дней?</h3>
         <form @submit.prevent="daysCounter">
            <select 
            class="form-select form-select-lg mb-3"
            v-model="categories.option"
            >
            <option 
            v-for="option in categories"
            :key="option"
            :value="option"
            :selected="option.categories === option"
            > {{ option }}</option>
             </select>
             <button
                style="width: 255px;font-size: 25px;"
                class="btn btn-primary"
                type="submit"
                :disabled="limit === 0"
                >Выбрать</button>
            </form>
        </div>
     <form
     v-if="isFreeze !== true && limit === 0" 
     @submit.prevent="createPost" class="mt-5">
         <label>ID</label><br>
         <p
          style="color:red;" 
          v-show="limit === 0">Прежде чем отправить запрос пожалуйста перепроверяйте свои ID!</p>
         <input
            v-model="hardware_id"
            type="text"
            placeholder="Ваш ID"
         ><br>
          <span v-show="limit === 0">Нажмите Гоу✓ чтобы получить ключ!</span><br>
        <button 
        style="width: 255px;font-size: 31px;"
        type="submit" class="btn btn-primary mt-2" :disabled="hardware_id.length < 10 || btnIF == 1 || BtnGoo == 0">Гоу✓</button><br>
        <p 
        class="pt-3"
        v-if="BtnGoo == 0">Подождите пожалуйста код с базы...</p><br>
     </form>
     <!-- <div v-else>
     <h4 style="font-family: 'vue-w25'; color: red;" class="mt-3">Ваш профиль заморожен пожалуйста обратитесь к Тех поддержке!</h4>
     </div> -->

     <form class="mt-5 mb-5" v-if="createLimit == 0">
         <label class="mb-3">Успех! - Нажмите "Перейти к Базе"</label><br>
         <router-link to="/activeKey" @click="updateNewItems" class="btn btn-primary TakeIt">Перейти к Базе ✓</router-link> 
     </form>
    
    </div>
    </div> 
 </div>
</template>

<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { onBeforeMount, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';

    const userKeys = window.localStorage.getItem('identification')
    const userKeysA = userKeys
    const userJwt = window.localStorage.getItem('sc_359649')
    const userJwtG = userJwt

    const router = useRouter();

    const Actually = ref('')
    const dateOKey = ref('')
    const IdHistory = ref('')

    const btn1 = ref(false)
    const btn2 = ref(false)
    const btn3 = ref(false)

    const InfKeys = ref('')
    const isFreeze = ref()
    const UserNameGet = ref('')
    const NewAmounts = ref('')
    const customer_name = ref("LpvoidUser")
    const email = ref("LpvoidUser@mail.ru")
    const hardware_id = ref('')

    onBeforeMount(async () => {
        await getAmount();
    }) 
    onMounted(async () => {
        await getAmount();
    })  

    const limit = ref(1)
    const createLimit = ref(1)
    const btnIF = ref(1)
    const BtnGoo = ref(1)
    const day2 = ref(2)
    const day7 = ref(7)
    const day30 = ref(30)

    const GoBtnFunc = () => {
        BtnGoo.value--
    }

    const categories =  [
        day2.value,
        day7.value,
        day30.value
    ]
    const TakeAShit = () => {
        router.push({path:'/activeKey'})
            setTimeout(() => {
                alert('Нажмите скопировать чтобы получить ключ')
                window.location.reload()
            }, 1000)
        SaveToBase()
    }

    const getAmount = async () => {
            await axios.get(`${configs.apiURL}/api/users/${userKeysA}`, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                }
             })
            .then(response => {
                    InfKeys.value = response.data.Amount
                    isFreeze.value = response.data.isFreeze
                    UserNameGet.value = response.data.username
                })
            .catch(err => {
                    console.log(err)
             })
                
        }

    const createPost = async () => {
        const infTime = ref(sessionStorage.getItem('time__'))
            await axios.post(`${configs.BackURL}/licenses`, {
                    "customer_name": customer_name.value,
                    "email": email.value,
                    "hardware_id": hardware_id.value,
                    "expiration_date": infTime.value,
                    "key": "hackCupUser"
             })
                .then(response => {
                    GoBtnFunc()
                    Actually.value = response.data.key
                    dateOKey.value = response.data.expiration_date
                    IdHistory.value = response.data.hardware_id
                    createLimit.value--
                })
                .catch((err) => {
                    if(err){
                        console.log('Log-2')
                    }
                } )
            }

    const updateNewItems = async () => {
        await axios({
            method: 'PUT',
                url: `${configs.apiURL}/api/users/${userKeysA}?fields=Amount`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userJwtG}`
                },
                data: {
                    'id': userKeysA,
                    'Amount': InfKeys.value
                }
            })
            .then(response => {
                NewAmounts.value = response.data.Amount
                window.sessionStorage.setItem('KeyFromBase', Actually.value)
                window.sessionStorage.setItem('DateFromBase', dateOKey.value) 
                window.sessionStorage.setItem('HWID__', IdHistory.value) 
                TakeAShit()
            })
            .catch(() => {
                alert('Что то не так!')
            })
        }  
    
    const countMe = () => {
        const daysCount = categories.option;
        const todayCount = new Date();

        const DateShowOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }

        const finalCount = new Date(todayCount.getFullYear(),todayCount.getMonth(),todayCount.getDate()+ daysCount)
        const finalCountDate = finalCount.toLocaleDateString("en-CA")
        const finalCountDateShow = finalCount.toLocaleDateString("ru-Ru", DateShowOptions)
        sessionStorage.setItem('time__', finalCountDate)
        sessionStorage.setItem('timeShow', finalCountDateShow)     
    }

    const errorCups = () => {
        limit.value++
            alert('У вас нехватает Монет')
    }

    const daysCounter = () => {
       if(categories.option == day2.value || categories.option == day7.value || categories.option == day30.value){
        countMe()
        limit.value--
        btnIF.value--

        if(categories.option === day2.value){
            if(InfKeys.value >= 1){
                InfKeys.value--
                btn1.value = true
            }
            else {
            errorCups();
            return;
           }
        }

        if(categories.option === day7.value){
           if(InfKeys.value >= 2){
                InfKeys.value--
                InfKeys.value--
                btn2.value = true
           } 
           else {
            errorCups();
            return;
           }
        }
        if(categories.option === day30.value){
            if(InfKeys.value >= 5){
                InfKeys.value--
                InfKeys.value--
                InfKeys.value--
                InfKeys.value--
                InfKeys.value--
                btn3.value = true
            }
            else {
                errorCups();
                return;
            }
        } 
       } 
       else {
        alert('Мм, Выбери период ключа! Дату блять!')
       }
    }
    const SaveToBase =  async () => {
        const ShowDates = ref(sessionStorage.getItem('timeShow'))
        await axios.post(`${configs.BackJSON}/Dota/`, 
            {
                "HWID": IdHistory.value,
                "KEY": Actually.value,
                "Time": dateOKey.value,
                "IDFSITE": userKeysA,
                "ShowDate": ShowDates.value,
                "USERNAME": UserNameGet.value
            })
            .then(() => {
            })
            .catch(() => {
                alert('Что-то не так!')
        })
    }
</script>

<style scoped>
h3{
    font-family: 'vue-w25';
}
input::placeholder {
    font-family: 'vue-w22';
    color: rgb(1, 1, 1);
}
.category-select{
    box-shadow: 0px 6px 30px 0px rgba(6, 127, 221, 0.2);
    padding: 15px;
    border-radius: 5px;
}
.mit{
    margin-top: 25px;
}
a{
    color: red;
}
a:hover{
    color: red;
    border-bottom: 1px solid red;
}
p {
    font-family: 'vue-w25';
    font-size: 15px;
}

iframe {
    width: 100%;
}
.reload-page{
    color: red;
    font-size: 40px;
}

.container-costs{
    display: flex;
    justify-content: space-evenly;
}
.container-costs img{
    width: 30px;
    height: auto;
}
.container-costs h4{
    font-size: 19px;
    font-family: 'vue-w25';
}
.costs{
    display: flex;
    flex-direction: column;
}
option{
    font-size: 21px;
    font-family: 'vue-w25';
}
h5{
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #61b606, 0 -10px 20px #76ba06, 0 -18px 40px #309506;
}
.TakeIt{
    font-size: 19px;
    color: #f5efef;
}

@media(max-width: 450px){
    .reload-page{
        color: red;
        font-size: 16px;
    }
    .container-costs{
        display: flex;
        flex-direction: column;
        margin-top: 95px;
    }
}
</style>